
    import {Component, Prop} from "vue-property-decorator";
    import AbpBase from "@/lib/abpbase";
    import JobAction from "@/store/entities/jobcontext/jobaction";

    @Component
    export default class JobActionForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;

        private jobAction: JobAction = new JobAction();

        async visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                this.jobAction = this.$store.state.jobAction.editJobAction;
            }
        }

        async save() {
            await this.$store.dispatch({
                type: 'jobAction/create',
                data: this.jobAction
            });
            this.$emit('save-success');
            this.$emit('input', false);
        }

        cancel() {
            this.$emit('input', false);
        }
    }
