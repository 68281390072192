

    import {Component, Prop, Watch} from "vue-property-decorator";
    import AbpBase from "../../../../lib/abpbase";
    import DocumentDynamicProperty from "@/store/entities/jobcontext/documentdynamicproperty";
    import DynamicLookUp from "@/store/entities/dynamiclookup";

    @Component
    export default class DocumentCustomProperties extends AbpBase {
        @Prop({default: new Array<DocumentDynamicProperty>()}) props: Array<DocumentDynamicProperty>;
        @Prop({type: Boolean, default: false}) value: boolean;
        @Prop({default: -1}) jobId: number;
        private obj: any = {};
        private containerId: number = 0;
        private containers: any;

        data() {
            return {
                containers: []
            }
        }

        @Watch('containerId')
        async onContainerId(val, oldVal) {
            this.$emit('container-changed', val);
        }


        save() {
            (this.$refs.dynamicPropertiesForm as any).resetFields();
            this.$emit('input-success', this.obj);
            this.$emit('input', false);
        }

        items(data) {
            return JSON.parse(data) as Array<DynamicLookUp>;
        }

        async visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                this.obj = {}
                for (const item of this.props) {
                    if (item.type !== 4) {
                        this.obj[item.name] = item.type === 1 ? false : null;
                    } else {
                        this.containers = await this.$store.dispatch('job/getContainersForLookUp', this.jobId);
                    }
                }
            }
        }
    }
