
import AbpBase from "@/lib/abpbase";
import {Component, Prop} from "vue-property-decorator";
import FormInput, {LookUpType} from "@/components/dynamic-forms/form-field";
import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";
import Job from "@/store/entities/jobcontext/job";
import CreateCompany from "@/views/setting/companycontext/company/create-company.vue";
import EditContainerType from "@/views/setting/containercontext/container/container-form.vue";
import Company from "@/store/entities/companycontext/company";
import Container from "@/store/entities/containercontext/container";
import JobCompanyKeyWordLookUpRequest from "@/store/entities/jobcontext/jobcompanykeywordkookuprequest";

@Component({
  components: {CreateCompany, EditContainerType}
})
export default class SelectInput extends AbpBase {
  @Prop({default: new FormInput()}) input: FormInput;
  @Prop({default: new Job()}) job: Job;
  @Prop({default: true}) useCustomStyling: boolean;
  @Prop({default: true}) disabled: boolean;

  @Prop() value: Object

  private createCompanyModalShow: boolean = false;
  private createContainerModalShow: boolean = false;

  get inputValue() {
    return this.value;
  }

  set inputValue(newValue) {
    this.$emit('input', newValue);
  }

  canceled() {
    if (this.input.lookUpType === LookUpType.Container) {
      this.job[this.input.property].pop();
    }

  }

  async putCreatedValue(val) {
    this.job[this.input.property] = val;
  }

  async create(val) {
    if (this.input.lookUpType === LookUpType.Company) {
      let company = new Company();
      company.name = val;
      if (this.input.categoryCode) {
        let category = await this.$store.dispatch('category/byCode', this.input.categoryCode);
        company.categories = new Array<number>();
        company.categories.push(category.id);
      }
      this.$store.commit('company/edit', company);
      this.createCompanyModalShow = true;
    }
    if (this.input.lookUpType === LookUpType.Container) {
      let container = new Container();
      container.number = val;
      this.$store.commit('container/edit', container);
      this.createContainerModalShow = true;
    }
  }

  changed(ev) {
    if (ev) {
      if (this.input.subjects !== null && this.input.subjects.length > 0) {
        this.input.subjects.forEach(x => {
          this.job[x.dependentProperty] = this.dataList.find(x => x.id === ev)[x.dictionaryKey];
        })
      }
      this.$emit('change', ev);
    } else {
      if (this.input.subjects !== null && this.input.subjects.length > 0) {
        this.input.subjects.forEach(x => {
          this.job[x.dependentProperty] = null;
        })
      }
    }
  }

  private dataList: any;
  private isLoading: boolean = false;

  data() {
    return {
      dataList: []
    }
  };

  async loadData(query: string) {
    if (query !== null && query.length >= 1) {
      this.isLoading = true;
      let id = this.job[this.input.property];
      switch (this.input.lookUpType) {
        case LookUpType.Company:
          this.dataList = await this.$store.dispatch('company/jobKeyWordLookUp', new JobCompanyKeyWordLookUpRequest(query, id, this.input.categoryCode, this.job.id));
          break;
        case LookUpType.TradeTransportLocation:
          this.dataList = await this.$store.dispatch('tradetransportlocation/keyWordLookUp', new KeyWordLookUpRequest(query, id));
          break;
        case LookUpType.Vessel:
          this.dataList = await this.$store.dispatch('vessel/lookUp', new KeyWordLookUpRequest(query, id));
          break;
        case LookUpType.AirlineCode:
          this.dataList = await this.$store.dispatch('airlinecode/lookUp', new KeyWordLookUpRequest(query, id));
          break;
      }
      this.isLoading = false;
    } else {
      this.dataList = [];
      this.isLoading = false;
    }
  }

  async mounted() {
    switch (this.input.lookUpType) {
      case LookUpType.Currency:
        this.dataList =  await this.$store.dispatch('currency/lookUp');
        break;
      case LookUpType.Incoterm:
        this.dataList = await this.$store.dispatch('incoterm/lookUp');
        break;
      case LookUpType.ShippingMethod:
        this.dataList = await this.$store.dispatch('order/shippingMethods');
        break;
      case LookUpType.PackageType:
        this.dataList = await this.$store.dispatch('packagetype/lookUp');
        break;
      case LookUpType.VehicleType:
        this.dataList = await this.$store.dispatch('vehicletype/lookUp');
        break;
      case LookUpType.ContainerSize:
        this.dataList = await this.$store.dispatch('containersize/lookUp');
        break;
      case LookUpType.MasterPrepaidCollect:
        this.dataList = await this.$store.dispatch('job/getMasterPrepaidCollection');
        break;
      case LookUpType.ContainerType:
        this.dataList = await this.$store.dispatch('containertype/lookUp');
        break;
      case LookUpType.ISOCode:
        this.dataList = await this.$store.dispatch('isocode/lookUp');
        break;
      case LookUpType.BillStatus:
        this.dataList = await this.$store.dispatch('job/getBillStatuses');
        break;
      case LookUpType.CompanyAddress:
        if (this.input.dependsOn) {
          if (this.job[this.input.dependsOn[0]]) {
            if (this.input.dependsOn[0] !== null && this.input.dependsOn[0] !== undefined) {
              this.dataList = await this.$store.dispatch('address/lookUp', this.job[this.input.dependsOn[0]]);
            } else {
              this.dataList = [];
            }
          }
        }
    }
    this.addWatch();
  }

  addWatch() {
    if (this.input.dependsOn) {
      this.input.dependsOn.forEach(x => this.$watch(
          function () {
            return this.job[x];
          },
          async function (newVal) {
            switch (this.input.lookUpType) {
              case LookUpType.Company:
                this.dataList = await this.$store.dispatch('company/relatedCompanies', newVal);
                break;
              case LookUpType.CompanyAddress:
                if (newVal !== null && newVal !== undefined) {
                  this.dataList = await this.$store.dispatch('address/lookUp', newVal);
                  break;
                } else {
                  this.dataList = [];
                  this.value = null;
                }
            }
          }));
    }
  }
}
