
import AbpBase from "../../../../lib/abpbase";
import {Component, Prop} from "vue-property-decorator";
import JobDocument from "../../../../store/entities/jobcontext/jobdocument";
import generatedocument from "@/store/entities/jobcontext/generatedocument";
import DocumentCustomProperties from "@/views/setting/jobcontext/job/document-custom-properties.vue";
import DocumentDynamicProperty from "@/store/entities/jobcontext/documentdynamicproperty";

@Component({
  components: {DocumentCustomProperties}
})
export default class JobDocumentUpload extends AbpBase {
  @Prop({default: -1}) jobId: number;
  @Prop({default: new JobDocument()}) jobDocument: JobDocument;
  @Prop({default: true}) disabled: boolean;
  @Prop({default: false}) isConsolidationLevel: boolean;
  @Prop({default: false}) isNotApplicable: boolean;
  private customProperties: Array<DocumentDynamicProperty> = new Array<DocumentDynamicProperty>();
  private customPropertiesModalShow: boolean = false;
  private containerId: number = null;

  containerChanged(val) {
    this.containerId = val;
  }

  async handleUpload(file) {
    let formData = new FormData();
    formData.append('documentId', this.jobDocument.documentId.toString());
    formData.append('id', this.jobDocument.id.toString());
    formData.append('jobId', this.jobDocument.jobId.toString());
    formData.append('isAutoGenerated', this.jobDocument.isAutoGenerated.toString())
    formData.append('isConsolidationLevel', this.isConsolidationLevel.toString())
    formData.append('file', file);
    await this.$store.dispatch({type: 'job/updateDocument', data: formData});
    this.$emit('save-success');
  }

  async previewDocument(id: number) {
    await this.$store.dispatch('job/previewDocument', id);
  }

  async download(id: number) {
    await this.$store.dispatch('job/downloadJobDocument', id);
  }

  async removeFile(id: number) {
    this.$Modal.confirm({
      title: this.L('Document'),
      content: this.L('Delete document confirmation'),
      okText: this.L('Yes'),
      cancelText: this.L('No'),
      onOk: async () => {
        await this.$store.dispatch('job/removeFile', id);
        this.$emit('save-success');
      }
    })
  }

  async removeDocumentFromView() {
    await this.$store.dispatch('job/markDocumentAsNotApplicable', {
      jobId: this.jobId,
      documentId: this.jobDocument.documentId
    })
    this.$emit('save-success')
    this.$emit('document-removed')
  }


  async putAdditionalData() {
    if (this.jobDocument.dynamicProperties === null || this.jobDocument.dynamicProperties.length === 0) {
      await this.generateDocument(null);
    } else {
      this.customProperties = this.jobDocument.dynamicProperties;
      this.customPropertiesModalShow = true;
    }
  }

  async generateDocument(additionData) {
    await this.$store.dispatch('job/generateDocument', new generatedocument(this.jobId, this.jobDocument.documentId, additionData, this.containerId));
    this.$emit('document-generated')
  }
}
