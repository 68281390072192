
    import {Component, Prop, Vue} from "vue-property-decorator";
    import Editor from '@tinymce/tinymce-vue'
    import {jobPlugin} from '../Tinymce/JobPropertiesList/plugin.js';
    import PropertyDescriptionDto from "@/store/entities/jobcontext/propertydescription";

    @Component({
        components: {
            Editor
        }
    })
    export default class Tinymce extends Vue {
        @Prop() value: string;
        @Prop({default: false}) readOnly: boolean = false;
        @Prop({default: new Array<PropertyDescriptionDto>()}) properties: Array<PropertyDescriptionDto>;
        private active: boolean = false;

        private inital = {
            setup: this.addCustomPlugins,
            height: 300,
            menubar: false,
            readonly: this.readOnly,
            plugins: [
                'jobPlugin advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
                'jobPlugin | undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help',
            valid_elements: '#p,-ol,-ul,-li,br,img[src|unselectable],-sub,-sup,-b,-i,-u'
        }

        private addCustomPlugins() {
            (window as any).tinymce.PluginManager.add('jobPlugin', jobPlugin(this.properties));
        }

        get inputValue() {
            return this.value;
        }

        set inputValue(newValue) {
            this.$emit('input', newValue);
        }

        mounted() {
            this.$nextTick(() => {
                this.active = true;
            });
        }
    }
