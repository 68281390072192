
import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import Action from '../../../../store/entities/jobcontext/action'

@Component
export default class SingleJobOverviewForm extends AbpBase {
  @Prop({type: Boolean, default: false}) value: boolean;
  @Prop({default: null}) jobId: number | null;

  private overview: any;

  data() {
    return {
      overview: null
    }
  }


  cancel() {
    this.$emit('input', false);
  }

  async visibleChange(value: boolean) {
    if (!value) {
      this.$emit('input', value);
    } else {
      this.overview = await this.$store.dispatch('job/getJobOverview', this.jobId);
    }
  }

  getColor(isAttached, isRequired) {
    if (isAttached) {
      return '#62ff00';
    } else if (isRequired) {
      return '#ff0000';
    }
    return '#ffd600';

  }
}
