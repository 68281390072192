
    import {Component, Prop} from "vue-property-decorator";
    import AbpBase from "../../../../lib/abpbase";
    import JobCompanyInstructionsComparisonVm from "@/store/entities/jobcontext/jobcompanyinstructionscomparisonvm";
    import JobInstructionApplyDto from "@/store/entities/jobinstructionapplydto";

    @Component
    export default class JobImportTable extends AbpBase {
        @Prop({default: new JobCompanyInstructionsComparisonVm()}) jobCompanyInstruction: JobCompanyInstructionsComparisonVm;


        async applyValueToJob(property) {
            await this.$store.dispatch({
                type: 'job/applyInstruction',
                data: new JobInstructionApplyDto(this.jobCompanyInstruction.jobId, this.jobCompanyInstruction.companyInstructionId, property)
            });
            this.$emit('save-success');
        }

        async applyValuesToJob() {
            await this.$store.dispatch({
                type: 'job/applyAllInstruction',
                data: new JobInstructionApplyDto(this.jobCompanyInstruction.jobId, this.jobCompanyInstruction.companyInstructionId, '')
            });
            this.$emit('save-success');
        }

        async applyValueToConsolidation(property) {
            await this.$store.dispatch({
                type: 'job/applyConsolidationInstruction',
                data: new JobInstructionApplyDto(this.jobCompanyInstruction.jobId, this.jobCompanyInstruction.companyInstructionId, property)
            });
            this.$emit('save-success');
        }

        async applyValuesToConsolidation() {
            await this.$store.dispatch({
                type: 'job/applyAllConsolidationInstruction',
                data: new JobInstructionApplyDto(this.jobCompanyInstruction.jobId, this.jobCompanyInstruction.companyInstructionId, '')
            });
            this.$emit('save-success');
        }

        columns = [{
            title: this.L('Field'),
            key: 'fieldName',
        }, {
            title: this.L('ValueInJob'),
            key: 'jobValue',
        }, {
            title: this.L('DefaultValue'),
            key: 'defaultValue'
        }, {
            title: this.L(' '),
            width: 350,
            renderHeader: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                await this.applyValuesToJob();
                            }
                        }
                    }, this.L('ApplyAll')),
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                await this.applyValuesToConsolidation();
                            }
                        }
                    }, this.L('Apply all to all jobs in consolidation'))
                ])
            },
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                await this.applyValueToJob(params.row.property);
                            }
                        }
                    }, this.L('Apply')),
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                await this.applyValueToConsolidation(params.row.property);
                            }
                        }
                    }, this.L('Apply to all jobs in consolidation'))
                ])
            }
        }]
    }
