
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '../../../../lib/util'
    import AbpBase from '../../../../lib/abpbase'
    import Document from '../../../../store/entities/jobcontext/document'
    import AddJobNode from "@/store/entities/jobcontext/addjobnode";
    import Job from "@/store/entities/jobcontext/job";
    import DynamicInput from "@/components/dynamic-forms/dynamic-input.vue";

    @Component({
        components: {DynamicInput}
    })
    export default class JobNodeMetadataForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        @Prop({default: new Job()}) job: Job;
        private items: any;

        data() {
            return {
                items: []
            }
        };

        async save() {
            await this.$store.dispatch({
                type: 'job/update',
                data: this.job
            });
            this.$emit('save-success');
            this.$emit('input', false);
        }

        cancel() {
            this.$emit('input', false);
        }


        async visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                this.items = this.$store.state.job.listNodeFieldsMetadata;
            }
        }

    }
