export default class GenerateDocumentDto {
    jobId: number;
    documentId: number;
    otherProperties: any;
    containerId: number;

    constructor(jobId: number, documentId: number, otherProperties, containerId: number) {
        this.jobId = jobId;
        this.documentId = documentId;
        this.otherProperties = otherProperties;
        this.containerId = containerId;
    }
}
