
import AbpBase from "../../lib/abpbase";
import {Component, Prop} from "vue-property-decorator";
import SelectInput from "@/components/dynamic-forms/select-input.vue";
import FormInput, {FieldTypes} from "./form-field";
import Job from "@/store/entities/jobcontext/job";
import JobField from "@/store/entities/jobcontext/jobfield";

@Component({
  components: {SelectInput}
})
export default class DynamicInput extends AbpBase {
  @Prop({default: new FormInput()}) input: FormInput;
  @Prop({default: new Job()}) job: Job;
  @Prop({default: false}) enableAddOrRemoveColumns: boolean;
  @Prop({default: true}) useCustomStyling: boolean;
  @Prop({default: true}) disabled: boolean;
  @Prop({default: false}) requiresToBeFilled: boolean;

  @Prop() value: Object

  async handleClick() {
    if (this.enableAddOrRemoveColumns) {
      await this.$store.dispatch({
        type: 'job/updateJobField',
        data: new JobField(this.job.id, this.input.property)
      });
      this.input.presents = !this.input.presents;
    }
  }

  getType() {
    if (this.input.fieldType === 0) {
      return 'number'
    }
    return 'text'
  }

  getBackgroundColor() {
    return this.requiresToBeFilled ? 'red' : 'transparent'
  }

  parser(val) {
  }

  formatDate(d) {
    if (d) {
      let date = new Date(d);
      let month = '' + (date.getMonth() + 1),
          day = '' + date.getDate(),
          year = date.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      let dateStr = [day, month, year].join('/');
      if (this.input.fieldType === FieldTypes.DateTime) {
        return dateStr + [date.getHours().toString(), date.getMinutes().toString(), date.getSeconds().toString(2)].join(':');
      }
      return dateStr;
    }
  }

  get inputValue() {
    if (this.input.fieldType === FieldTypes.DateTime || this.input.fieldType === FieldTypes.Date) {
      if (this.value !== null && this.value.toString() !== '') {
        return this.formatDate(this.value);
      }
    }
    if (this.input.fieldType === 0) {
      if (this.value !== null && this.value.toString() !== '') {
        return Number.parseFloat(this.value.toString())
      }
    }
    return this.value;
  }

  set inputValue(newValue) {
    this.$emit('input', newValue);
  }
}
