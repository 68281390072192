export default class JobContainersLookUpRequest {
    keyWord: string;
    ids: Array<number>;
    id: number;

    constructor(keyword: string, ids: Array<number>, id: number) {
        this.keyWord = keyword;
        if (ids) {
            this.ids = new Array<number>();
        }
        if (id) {
            this.id = id;
        }
    }
}
