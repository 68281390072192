const jobPlugin = function (items) {
    return function (editor) {

        let _typeOptions = items.map(x => {
            return {
                type: 'menuitem',
                text: x.description,
                onAction: function () {
                    if (x.property === 'Documents') {
                        console.log(editor);
                        editor.insertContent('@foreach (var item in Model.Documents) ' +' { @Raw(\"<p>\") @item @Raw(\"</p>\")}');
                    } else {
                        editor.insertContent('@Model.' + x.property);
                    }
                }
            }
        });

        editor.ui.registry.addMenuButton('jobPlugin', {
            text: 'Job property',
            fetch: function (callback) {
                callback(_typeOptions);
            }
        });
    }

}

export {jobPlugin};
