import Entity from '../entity'
import LookUp from "@/store/entities/lookup";
import DocumentDynamicProperty from "@/store/entities/jobcontext/documentdynamicproperty";

export default class JobDocument extends Entity<number> {
    jobId: number;
    documentId: number;
    documentName: string;
    files: Array<LookUp>;
    isAutoGenerated: boolean;
    dynamicProperties: Array<DocumentDynamicProperty>;
}
