
    import {Component, Prop, Watch} from "vue-property-decorator";
    import AbpBase from "@/lib/abpbase";
    import DocumentRequest from "@/store/entities/jobcontext/documentrequest";

    @Component
    export default class JobDocumentRequestForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        @Prop({default: 0}) jobId: number;
        private jobDocumentRequest: DocumentRequest = new DocumentRequest();
        private documents: any;
        private clientContacts: any;
        private shipperContacts: any;
        private consigneeContacts: any;
        private agentContacts: any;
        private supplierContacts: any;
        private spinShow: boolean = false;

        data() {
            return {
                documents: [],
                clientContacts: [],
                shipperContacts: [],
                consigneeContacts: [],
                agentContacts: [],
                supplierContacts: []
            }
        }

        async getClientContacts(value) {
            if (value) {
                this.clientContacts = await this.getCompanyContacts(this.jobDocumentRequest.clientId);
            } else {
                (this.$refs.clientContacts as any).clearSingleSelect();
                this.clientContacts = [];
            }
        }

        async getShipperContacts(value) {
            if (value) {
                this.shipperContacts = await this.getCompanyContacts(this.jobDocumentRequest.shipperId);
            } else {
                (this.$refs.shipperContacts as any).clearSingleSelect();
                this.shipperContacts = [];
            }
        }

        async getConsigneeContacts(value) {
            if (value) {
                this.consigneeContacts = await this.getCompanyContacts(this.jobDocumentRequest.consigneeId);
            } else {
                (this.$refs.consigneeContacts as any).clearSingleSelect();
                this.consigneeContacts = [];
            }
        }

        async getAgentContacts(value) {
            if (value) {
                this.agentContacts = await this.getCompanyContacts(this.jobDocumentRequest.agentId);
            } else {
                (this.$refs.agentContacts as any).clearSingleSelect();
                this.agentContacts = [];
            }
        }

        async getSupplierContacts(value) {
            if (value) {
                this.supplierContacts = await this.getCompanyContacts(this.jobDocumentRequest.supplierId);
            } else {
                (this.$refs.supplierContacts as any).clearSingleSelect();
                this.supplierContacts = [];
            }
        }

        async visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                this.jobDocumentRequest = await this.$store.dispatch('job/documentRequest', this.jobId);
                this.documents = await this.$store.dispatch('job/missedDocuments', this.jobId);
            }
        }

        checkAbilityToSelect(id) {
            return id !== undefined && id !== null;
        }

        async getCompanyContacts(companyId: number) {
            return await this.$store.dispatch('company/getContacts', companyId);
        }

        cancel() {
            this.$emit('cancel');
        }

        async submit() {
            (this.$refs.documentRequestForm as any).validate(async (valid: boolean) => {
                if (valid) {
                    this.spinShow = true;
                    try {
                        await this.$store.dispatch({
                            type: 'jobDocumentRequest/create',
                            data: this.jobDocumentRequest
                        });
                    } finally {
                        this.spinShow = false;
                    }
                    (this.$refs.documentRequestForm as any).resetFields();
                    this.$emit('save-success');
                }
            });

        }


        checkConcatInput(rule, value, callback) {
            if (this.jobDocumentRequest.supplierContactId !== null || this.jobDocumentRequest.agentContactId !== null || this.jobDocumentRequest.consigneeContactId !== null || this.jobDocumentRequest.clientContactId !== null || this.jobDocumentRequest.shipperContactId !== null) {
                return callback();
            } else {
                return callback(new Error(this.L('OneOfTheFieldsAreRequired', undefined, this.L('Contact'))));
            }
        }

        checkCheckBoxInput(rule, value, callback) {
            if (this.jobDocumentRequest.toAgent || this.jobDocumentRequest.toConsignee || this.jobDocumentRequest.toShipper || this.jobDocumentRequest.toSupplier || this.jobDocumentRequest.toClient) {
                return callback();
            } else {
                return callback(new Error(this.L('CheckOneOfTheCheckboxesToSelectContact', undefined, this.L('Contact'))));
            }
        }


        rules = {
            contact: [{
                validator: this.checkConcatInput, trigger: 'change'
            }],
            checkBox: [{
                validator: this.checkCheckBoxInput, trigger: 'change'
            }],
            documents: [{
                required: true,
                validator: (rule, value, callback) => {
                    if (value === null || value.length === 0) {
                        return callback(new Error(this.L('DocumentsAreRequired', undefined, this.L('Documents'))));
                    } else {
                        return callback();
                    }
                },
                trigger: 'change'
            }]
        }
    }

