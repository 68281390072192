
import AbpBase from "../../../../lib/abpbase";
import {Component, Prop, Watch} from "vue-property-decorator";
import DynamicInput from "@/components/dynamic-forms/dynamic-input.vue";
import Job from "@/store/entities/jobcontext/job";
import {_} from 'vue-underscore';
import FormInput, {PositionType, SectionNumber} from "@/components/dynamic-forms/form-field";
import JobDocumentUpload from "@/views/setting/jobcontext/job/job-document.vue";
import JobAction from "@/views/setting/jobcontext/job/job-action.vue";
import CreateTemplateForm from "@/views/setting/jobcontext/job/template.vue";
import JobNodeMetadataForm from "@/views/setting/jobcontext/job/job-node-metadata.vue";
import JobContainerForm from "@/views/setting/jobcontext/job/jobcontainer-form.vue";
import JobImportForm from "@/views/setting/jobcontext/job/job-import-form.vue";
import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";
import CompanyKeyWordLookUpRequest from "@/store/entities/ordercontext/company-keyword-lookup-request";
import JobContainer from "@/store/entities/jobcontext/jobcontainer";
import CreateJobForm from "@/views/setting/jobcontext/job/job-create.vue";
import SendEmailForm from "@/views/setting/jobcontext/job/sendemail-form.vue";
import JobDocumentRequestForm from "@/views/setting/jobcontext/job/documentrequest-form.vue";
import SingleJobOverviewForm from "@/views/setting/jobcontext/job/single-job-overview-form.vue";
import JobItemForm from "@/views/setting/jobcontext/job/job-items.vue";
import JobItem from '@/store/entities/jobcontext/jobitem';

@Component({
  components: {
    DynamicInput,
    JobDocumentUpload,
    CreateJobForm,
    SingleJobOverviewForm,
    JobAction,
    CreateTemplateForm,
    JobNodeMetadataForm,
    JobContainerForm,
    JobImportForm,
    SendEmailForm,
    JobDocumentRequestForm,
    JobItemForm
  }
})
export default class SectionGroupedJobForm extends AbpBase {
  @Watch('job', {
    deep: true
  })
  async onJob(val, oldVal) {
    if (val) {
      if (oldVal.id === undefined) {
        await this.loadClient();
        await this.loadDeliveryAddresses();
        await this.getUsers('');
        this.getJobJsonString();
      } else {
        if (this.isChanged) return;
        let str = JSON.stringify(val, (key, value) => {
          if (value !== null && value !== '') return value;
        });
        if (str !== this.jobJson && this.jobJson !== '') {
          this.jobJson = str;
          this.isChanged = true;
        }
      }
    }
  }

  getJobJsonString() {
    this.jobJson = JSON.stringify(this.job, (key, value) => {
      if (value !== null && value !== "") return value;
    });
  }

  public isChanged: boolean;
  @Prop({default: new Job()}) job: Job;
  @Prop({default: new Array<FormInput>()}) metadata: Array<FormInput>
  @Prop({default: -1}) jobId: number;
  private consolidations: any;
  private shippingOrders: any;
  private documents: any;
  private consoleDocuments: any;
  private notApplicableDocuments: any;
  private actions: any;
  private companies: any;
  private jobTemplates: any;
  private requiredFields: any;
  private exportDataModalShow: boolean = false;
  private enableAddOrRemoveColumns: boolean = false;
  private createTemplate: boolean = false;
  private importDataModalShow: boolean = false;
  private overviewModalShow: boolean = false;
  private importDataDisabled: boolean = true;
  private deliveryAddresses: any;
  private createJobEnabled: boolean = false;
  private createModalShow: boolean = false;
  private sendEmailModalShow: boolean = false;
  private documentRequestModalShow: boolean = false;
  private users: any;
  private jobJson: string = "";
  private tags: any;
  private packageTypes: any;
  private isNotApplicableDocumentsClicked = false;

  async clientChange() {
    await this.loadDeliveryAddresses();
  }

  requestSubmitted() {
    this.documentRequestModalShow = false;
    this.$emit('document-request-submitted')
  }

  async unlock() {
    let that = this;
    this.$Modal.confirm({
      title: this.L('Tips'),
      content: this.L('This job is locked. Are You sure to unlock it?'),
      okText: this.L('Yes'),
      cancelText: this.L('No'),
      onOk: async () => {
        await this.$store.dispatch({type: 'job/unlock', data: this.job.id})
        await that.$emit('data-updated');
      }
    })
  }

  documentRequestCancel() {
    this.documentRequestModalShow = false;
  }

  async updateContainers() {
    this.job.jobContainers = await this.$store.dispatch('job/getContainersForJob', this.jobId);
  }

  async updateJobTemplate() {
    await this.$store.dispatch('job/updateJobTemplate', {
      jobId: this.job.id,
      templateId: this.job.jobTemplateId
    });
    this.$emit('template-updated');
    await this.updateData();
    this.$Modal.success({title: 'Template successfully changed'});
    this.enableAddOrRemoveColumns = !this.enableAddOrRemoveColumns;
  }

  async isCompanyTextChanged(val: string) {
    if (val != null && val.length >= 3) {
      await this.getCompanies(val);
    }
  }

  async isOwnerTextChanged(val: string) {
    if (val != null && val.length >= 1) {
      await this.getUsers(val);
    }
  }

  async getCompanies(query) {
    this.companies = await this.$store.dispatch('company/keyWordLookUp', new CompanyKeyWordLookUpRequest(query, this.job.clientId, null));
  }

  async showRemovedDocuments() {
    this.isNotApplicableDocumentsClicked = !this.isNotApplicableDocumentsClicked
    if (this.isNotApplicableDocumentsClicked) {
      await this.getNotApplicableDocuments()
    }
  }

  async documentRemovedFromList() {
    if (this.isNotApplicableDocumentsClicked) {
      await this.getNotApplicableDocuments()
    }
  }

  async reinstateDocument(documentId) {
    await this.$store.dispatch('job/markDocumentAsApplicable', {
      jobId: this.jobId,
      documentId: documentId
    })
    await this.getDocuments(this.jobId);
    await this.getNotApplicableDocuments();
  }

  async getUsers(query) {
    this.users = await this.$store.dispatch('user/keyWordLookUp', new KeyWordLookUpRequest(query, this.job.ownerId));
  }

  requestDocument() {
    this.documentRequestModalShow = true;
  }

  importData() {
    this.importDataModalShow = true;
  }

  showOverview() {
    this.overviewModalShow = true;
  }

  exportData() {
    this.exportDataModalShow = true;
  }

  sendEmail() {
    this.sendEmailModalShow = true;
  }

  async updateData() {
    await this.init();
    await this.$emit('data-updated');
  }

  addJobContainer() {
    this.job.jobContainers.push(new JobContainer());
  }

  eyeClick() {
    this.enableAddOrRemoveColumns = !this.enableAddOrRemoveColumns;
  }

  saveAsTemplate() {
    this.createTemplate = true;
  }

  async changeRow(currentRow) {
    await this.$router.push({name: 'job-form', params: {jobId: currentRow.id.toString()}});
    this.$router.go(0);
  }

  getLocationItems(data, position: PositionType) {
    return _.filter(_.sortBy(data, x => x.number), x => x.positionType === position);
  }

  getSectionColor(data) {
    if (data !== null && data.length > 0)
      switch (data[0].section) {
        case SectionNumber.First:
          return 'firstSection';
        case SectionNumber.Second:
          return 'secondSection';
        case SectionNumber.Third:
          return 'thirdSection';
        case SectionNumber.Fourth:
          return 'fourthSection';
        case SectionNumber.Fifth:
          return 'fifthSection';
        case SectionNumber.Sixth:
          return 'sixthSection';
        case SectionNumber.Seventh:
          return 'seventhSection';
        case SectionNumber.Eighth:
          return 'eighthSection';
        case SectionNumber.Ninth:
          return 'ninthSection';
        default:
          return 'default';
      }
    return 'default';
  }

  data() {
    return {
      consolidations: [],
      documents: [],
      consoleDocuments: [],
      shippingOrders: [],
      actions: [],
      users: [],
      packageTypes: [],
      tags: [],
      companies: [],
      deliveryAddresses: [],
      jobTemplates: [],
      requiredFields: [],
      notApplicableDocuments: [],
    }
  };

  async edit(id: number) {
    await this.$router.push({name: 'job-form', params: {jobId: id.toString()}});
    this.$router.go(0);
  }


  async getDocuments(jobId: number) {
    this.documents = await this.$store.dispatch('job/getJobDocuments', jobId);
  }

  async getConsolidationDocuments(jobId: number) {
    this.consoleDocuments = await this.$store.dispatch('job/getConsolidationJobDocuments', jobId);
  }

  actionUpdated() {
    this.$emit('action-updated');
  }

  async getActions(jobId: number) {
    this.actions = await this.$store.dispatch('jobAction/getAll', jobId);
  }

  get consolidationItems() {
    return _.groupBy(_.filter(this.metadata, x => x.isConsolidation && x.section !== null), x => x.section);
  }

  get list() {
    let index = this.$store.state.job.consolidationJobs.findIndex(x => this.job.id === x.id);
    if (index !== -1) {
      this.$store.state.job.consolidationJobs[index]._highlight = true;
    }
    return this.$store.state.job.consolidationJobs;
  }

  get jobItems() {
    return _.groupBy(_.filter(this.metadata, x => !x.isConsolidation && x.section !== null), x => x.section);
  }

  get items() {
    return _.groupBy(this.metadata, x => x.section);
  }

  isShippingOrderLookUpEnabled() {
    return this.job.shippingOrderId == null;
  }

  async init() {
    this.consolidations = await this.$store.dispatch('job/getConsolidation', this.jobId);
    this.shippingOrders = await this.$store.dispatch('job/getShippingOrders', this.jobId)
    await this.getDocuments(this.jobId);
    await this.getConsolidationDocuments(this.jobId);
    await this.getActions(this.jobId);
    this.importDataDisabled = this.job.importDisabled;
    this.createJobEnabled = this.job.createJobEnabled;
  }

  async loadDeliveryAddresses() {
    if (this.job.consigneeId) {
      this.deliveryAddresses = await this.$store.dispatch('address/lookUp', this.job.consigneeId);
    }
  }

  async getJobTemplates() {
    this.jobTemplates = await this.$store.dispatch('jobTemplate/lookUp');
  }

  async getRequiredFields() {
    this.requiredFields = await this.$store.dispatch('job/getRequiredFields', this.jobId);
  }

  isRequiringToBeFilled(fieldName: string) {
    if (this.requiredFields.indexOf(fieldName) > -1) {
      if (!this.job[fieldName]) {
        return true;
      }
      return false;
    }
    return false;
  }

  async loadClient() {
    await this.getCompanies('');
  }

  async mounted() {
    await this.init();
    await this.getJobTemplates();
    await this.getRequiredFields();
    this.tags = await this.$store.dispatch('tag/lookUp');
    this.packageTypes = await this.$store.dispatch('packagetype/lookUp');
  }

  async getNotApplicableDocuments() {
    this.notApplicableDocuments = await this.$store.dispatch('job/getNotApplicableDocuments', this.jobId)
  }


  async createJob() {
    this.createModalShow = true;
  }

  columns = [{
    title: this.L('#'),
    key: 'number'
  }, {
    title: this.L('Cons'),
    key: 'consolidationNumber'
  }, {
    title: this.L('Client'),
    key: 'client'
  }, {
    title: this.L('Supplier'),
    key: 'supplier'
  }, {
    title: this.L('Consignee'),
    key: 'consignee'
  }, {
    title: this.L('PO'),
    key: 'po'
  }, {
    title: this.L('ETD'),
    key: 'etd',
    render: (h: any, params: any) => {
      return h('span', params.row.etd === null ? "" : new Date(params.row.etd).toLocaleDateString())
    }
  }, {
    title: this.L('ETA'),
    key: 'eta',
    render: (h: any, params: any) => {
      return h('span', params.row.eta === null ? "" : new Date(params.row.eta).toLocaleDateString())
    }
  }, {
    title: this.L('Origin'),
    key: 'origin'
  }, {
    title: this.L('Destination'),
    key: 'destination'
  }, {
    title: this.L('Node'),
    key: 'node'
  }, {
    title: this.L('Shipping Method'),
    key: 'shippingMethod',
    width: 170
  }, {
    title: this.L('EST DELIVERY DATE'),
    key: 'estDeliveryDate',
    render: (h: any, params: any) => {
      return h('span', params.row.estDeliveryDate === null || params.row.estDeliveryDate.length === 0 ? "" : new Date(params.row.estDeliveryDate).toLocaleDateString())
    }
  }]

}
