
import AbpBase from "../../../../lib/abpbase";
import {Component, Prop} from "vue-property-decorator";
import JobItem from "@/store/entities/jobcontext/jobitem";

@Component
export default class JobItemForm extends AbpBase {
  @Prop({
    default() {
      return new Array<JobItem>()
    }
  }) jobItems;
  private packageTypes = [];

  async mounted() {
    this.packageTypes = await this.$store.dispatch('packagetype/lookUp');
  }

  lengthChanged(val, index){
    if (val === undefined || val === null || val === ''){
      this.jobItems[index].length = 0;
    }
  }

  widthChanged(val, index){
    if (val === undefined || val === null || val === ''){
      this.jobItems[index].width = 0;
    }
  }

  heightChanged(val, index){
    if (val === undefined || val === null || val === ''){
      this.jobItems[index].height = 0;
    }
  }

  weightPerItemChanged(val, index){
    if (val === undefined || val === null || val === ''){
      this.jobItems[index].weightPerItem = 0;
    }
  }

  numberOfItemsChanged(val, index){
    if (val === undefined || val === null || val === ''){
      this.jobItems[index].numberOfItems = 0;
    }
  }

  parser(val) {
    return val.replace('.', '')
  }

  removeRow(index) {
    this.jobItems.splice(index, 1);
  }

  addRow() {
    let jobItem = new JobItem();
    jobItem.packageTypeId = this.packageTypes[0].id;
    this.jobItems.push(jobItem);
  }
}
