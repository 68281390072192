
import AbpBase from "@/lib/abpbase";
import {Component, Prop} from "vue-property-decorator";
import Job from "@/store/entities/jobcontext/job";
import FormInput, {PositionType} from "@/components/dynamic-forms/form-field";
import {_} from 'vue-underscore';
import DynamicInput from "@/components/dynamic-forms/dynamic-input.vue";
import JobNodeForm from "@/views/setting/jobcontext/job/jobnode-form.vue";
import JobNodeMetadataTable from "@/views/setting/jobcontext/job/jobnodemetadata-table.vue";
import JobNodeMetadataRequest from "@/store/entities/jobcontext/jobnodemetadatarequest";
import UpdateCurrentJobNode from "@/store/entities/jobcontext/updatecurrentjobnode";

@Component({
  components: {DynamicInput, JobNodeForm, JobNodeMetadataTable}
})
export default class NodeGroupedJobForm extends AbpBase {
  @Prop({default: new Job()}) job: Job;
  @Prop({default: new Array<FormInput>()}) metadata: Array<FormInput>
  @Prop({default: -1}) jobId: number;
  @Prop({default: true}) disabled: boolean;
  private jobNodeModalShow: boolean = false;
  private currentNodeId: number;
  private jobNodeDocuments = [];
  private jobNodeActions = [];
  private jobNodeMetadata = [];


  async getNodeDocuments(input: JobNodeMetadataRequest) {
    this.jobNodeDocuments = await this.$store.dispatch('job/getJobNodeDocumentsMetadata', input);
  }

  async getNodeActions(input: JobNodeMetadataRequest) {
    this.jobNodeActions = await this.$store.dispatch('job/getJobNodeActionsMetadata', input);
  }

  async getNodeFields(input: JobNodeMetadataRequest) {
    this.jobNodeMetadata = await this.$store.dispatch('job/getJobNodeFieldsMetadata', input);
  }


  async updateNodeRelatedData(input: JobNodeMetadataRequest) {
    await this.getNodeDocuments(input);
    await this.getNodeActions(input);
    await this.getNodeFields(input)
  }

  nodeAdd() {
    this.$store.commit('jobNode/setJobId', this.jobId);
    this.jobNodeModalShow = true;
  }

  async updateCurrentJobNode(nodeId: number) {
    await this.$store.dispatch('job/updateJobCurrentNode', new UpdateCurrentJobNode(this.jobId, nodeId));
    this.job.currentNodeId = nodeId;
    this.$emit('node-changed');
  }

  async changeRow(currentRow) {
    this.currentNodeId = currentRow.nodeId;
    await this.updateNodeRelatedData(new JobNodeMetadataRequest(this.jobId, currentRow.nodeId));
  }

  async updateActions() {
    if (this.currentNodeId !== undefined || this.currentNodeId !== 0) {
      await this.getNodeActions(new JobNodeMetadataRequest(this.jobId, this.currentNodeId));
    }
  }

  get loading() {
    return this.$store.state.jobNode.loading;
  }


  get items() {
    let filteredData = _.filter(this.metadata, x => x.nodeId !== null);
    return _.groupBy(_.sortBy(filteredData, x => x.order), x => x.order);
  }

  nodeColumns = [{
    title: '',
    width: 50,
    slot: 'select'
  },
    {
      title: this.L('Order'),
      key: 'order',
      width: 80
    }, {
      title: this.L('Name'),
      key: 'nodeName'
    }, {
      title: this.L('ReleaseDate'),
      key: 'releaseDate',
      width: 200,
      slot: 'releaseDate'
    }, {
      title: this.L('Done'),
      key: 'isDone',
      width: 80,
      slot: 'isDone'
    }]

  get list() {
    if (this.$store.state.jobNode.list && this.$store.state.jobNode.list.length > 0) {
      this.$store.state.jobNode.list[0]._highlight = true;
      this.changeRow(this.$store.state.jobNode.list[0]);
    }
    return this.$store.state.jobNode.list;
  }

  async getNodes(jobId: number) {
    await this.$store.dispatch('jobNode/getRelated', jobId);
    this.$emit('node-updated');
  }

  async mounted() {
    await this.getNodes(this.jobId);
  }
}
