
    import AbpBase from "../../../../lib/abpbase";
    import {Prop, Component} from "vue-property-decorator";
    import JodActionDto from '../../../../store/entities/jobcontext/jobaction'

    import JobActionForm from "@/views/setting/jobcontext/job/job-action-form.vue";

    @Component({
        components: {JobActionForm}
    })
    export default class JobAction extends AbpBase {
        @Prop({default: new JodActionDto()}) jobAction: JodActionDto;
      @Prop({default: true}) disabled: boolean;

        getStyle() {
            if (this.jobAction.note === null || this.jobAction.note === '') {
                return {};
            } else {
                return {backgroundColor: '#0af60a'}
            }
        }

        createModalShow: boolean = false;

        async createJobAction() {
            this.createModalShow = true;
            await this.$store.commit('jobAction/edit', this.jobAction);
        }

        updateData() {
            this.$emit('action-updated');
            this.$emit('save-success');
            this.$emit('input', false);
        }
    }
