export default class DocumentRequest {
    jobId: number;
    orderNumber: number | null;
    clientId: number | null;
    shipperId: number | null;
    consigneeId: number | null;
    agentId: number | null;
    toShipper: boolean;
    toClient: boolean;
    toConsignee: boolean;
    toAgent: boolean;
    documents: Array<number>;
    clientContactId: number | null;
    shipperContactId: number | null;
    agentContactId: number | null;
    consigneeContactId: number | null;
    supplierId: number;
    supplierContactId: number;
    toSupplier: boolean;
}
