import JobNodeVm from "@/store/entities/jobcontext/jobnodevm";

export default class UpdateJobNode {
    jobId: number;
    nodes: Array<JobNodeVm>;

    constructor(jobId: number, nodes: Array<JobNodeVm>) {
        this.jobId = jobId;
        this.nodes = nodes;
    }
}
